// extracted by mini-css-extract-plugin
export var relativeWrapper = "index-module--relativeWrapper--3l8mD";
export var decor = "index-module--decor--4uxVy";
export var button = "index-module--button--MfSmX";
export var lineAbove = "index-module--lineAbove--qQ6zJ";
export var container = "index-module--container--2ZuK7";
export var mobile = "index-module--mobile--1cXMs";
export var content = "index-module--content--sNipn";
export var teamList = "index-module--teamList--2vF7T";
export var expanded = "index-module--expanded--3huGt";
export var teamItem = "index-module--teamItem--2RMfX";
export var image = "index-module--image--3Mn8M";
export var name = "index-module--name--3mHjL";